<template>
  <div v-if="model && bloggerOptions">
    <h3 class="statistics__format--title">Заполните статистику аккаунта</h3>
    <div @click="showFillExample = true" class="textbluemobile">Как заполнять статистику</div>
    <div class="statistics__row flex-md-wrap flex-lg-nowrap">
      <div class="statistics__col">
        <div class="settings__info--wrap statistics__info--wrap">
          <h4 class="settings__title--small" style="margin-top: 0">
            <span style="font-weight: bold">Охваты</span> сторис
          </h4>
          <div class="statistics__title--desc">
            Заполните поля и загрузите подтверждающий скриншот с охватами сторис
          </div>
          <div class="w-100 set-fields">
            <b-form
              :style="{ position: 'relative', opacity: noStories ? '0.5' : '1' }"
              class="align-items-end flex-nowrap d-flex flex-row justify-content-between justify-content-sm-start justify-content-lg-between"
              style="max-width: 450px !important"
            >
              <b-form-group class="w-30 mr-1">
                <div class="settings__info--text--up">Минимум</div>
                <b-input
                  v-symbolic-filter="/[^0-9]/g"
                  v-model.number="model.blogger_profile.coverage_min"
                  :class="{
                    error: $v.model.blogger_profile.coverage_min.$anyError,
                  }"
                  class="h-36"
                  type="text"
                  name="stories"
                  id="stories1"
                />
              </b-form-group>
              <b-form-group class="w-30 mr-1">
                <div class="settings__info--text--up">Максимум</div>
                <b-input
                  v-symbolic-filter="/[^0-9]/g"
                  v-model.number="model.blogger_profile.coverage_max"
                  :class="{
                    error: $v.model.blogger_profile.coverage_max.$anyError,
                  }"
                  type="text"
                  name="stories"
                  id="stories22"
                  class="h-36"
                />
              </b-form-group>
              <b-form-group class="mr-1"
                ><SingleFileInput
                  @input="
                    (val) => {
                      uploadScreens({ coverage: val });
                      coverageScreen = val;
                    }
                  "
                  :value="coverageScreen"
                  :selected="model.blogger_profile.coverage_screen.hasOwnProperty('full_size')"
              /></b-form-group>
              <div
                v-if="noStories"
                style="
                  color: white;
                  opacity: 0.5;
                  position: absolute;
                  z-index: 30;
                  width: 100%;
                  height: 100%;
                "
              ></div>
            </b-form>
            <div class="settings__info--text--down"></div>
          </div>
          <div
            @click="
              () => {
                noStories = !noStories;
                model.blogger_profile.coverage_min = 0;
                model.blogger_profile.coverage_max = 0;
                coverageScreen = null;
              }
            "
            class="settings__info--text--blue stata__notadvert"
            style="cursor: pointer"
          >
            {{ noStories ? 'П' : 'Не п' }}родаю рекламу в сторис и {{ noStories ? '' : 'не' }} хочу
            заполнять охваты сторис
          </div>
        </div>
        <div class="settings__info--wrap statistics__info--wrap">
          <h4 class="settings__title--small">
            <span style="font-weight: bold">Охваты</span> постов
          </h4>
          <div class="statistics__title--desc">
            Заполните поля и загрузите подтверждающий скриншот с охватами постов
          </div>
          <div class="w-100 set-fields">
            <b-form
              class="align-items-end flex-nowrap d-flex flex-row justify-content-between justify-content-sm-start justify-content-lg-between"
              style="max-width: 450px !important"
            >
              <b-form-group class="w-30 mr-1">
                <div class="settings__info--text--up">Минимум</div>
                <b-input
                  :class="{
                    error: $v.model.blogger_profile.coverage_posts_min.$anyError,
                  }"
                  v-symbolic-filter="/[^0-9]/g"
                  v-model.number="model.blogger_profile.coverage_posts_min"
                  type="text"
                  class="h-36"
                  name="stories"
                  id="stories3"
                />
              </b-form-group>
              <b-form-group class="w-30 mr-1">
                <div class="settings__info--text--up">Максимум</div>
                <b-input
                  v-symbolic-filter="/[^0-9]/g"
                  :class="{
                    error: $v.model.blogger_profile.coverage_posts_max.$anyError,
                  }"
                  v-model.number="model.blogger_profile.coverage_posts_max"
                  type="text"
                  class="h-36"
                  name="stories"
                  id="stories4"
                />
              </b-form-group>
              <b-form-group class="mr-1"
                ><SingleFileInput
                  @input="
                    (val) => {
                      uploadScreens({ posts: val });
                      postCoverageScreen = val;
                    }
                  "
                  :value="postCoverageScreen"
                  :selected="
                    model.blogger_profile.posts_coverage_screen.hasOwnProperty('full_size')
                  "
              /></b-form-group>
            </b-form>

            <div class="settings__info--text--down settings__info--text--down--error"></div>
          </div>
        </div>
        <div class="settings__info--wrap statistics__info--wrap">
          <h4 class="settings__title--small">
            <span style="font-weight: bold">Процент</span> женщин
          </h4>
          <div class="statistics__title--desc">
            Заполните поле и загрузите подтверждающий скриншот с процентом женщин
          </div>
          <div class="w-100 set-fields">
            <b-form class="align-items-end flex-nowrap d-flex flex-row justify-content-start">
              <b-form-group class="w-25 mr-3 statistics__percent">
                <b-input
                  :class="{ error: $v.model.blogger_profile.females_percent.$anyError }"
                  v-model.number="model.blogger_profile.females_percent"
                  type="text"
                  class="h-36"
                  name="stories"
                  id="stories5"
                  v-symbolic-filter="/[^0-9]/g"
                />
              </b-form-group>
              <b-form-group class="mr-1">
                <SingleFileInput
                  @input="
                    (val) => {
                      uploadScreens({ women: val });
                      womenScreen = val;
                    }
                  "
                  :value="womenScreen"
                  :selected="
                    model.blogger_profile.women_percent_screen.hasOwnProperty('full_size')
                  "
              /></b-form-group>
            </b-form>

            <div class="settings__info--text--down"></div>
          </div>
          <!-- <div class="settings__info--text--blue">Не продаю рекламу в сторис и не хочу заполнять охваты сторис</div> -->
        </div>
        <div class="settings__info--wrap statistics__info--wrap">
          <h4 class="settings__title--small">
            Преобладающий возраст в <span style="font-weight: bold">процентах</span>
          </h4>
          <div class="statistics__title--desc">
            Заполните поля и загрузите подтверждающий скриншот с возрастом в процентах
          </div>

          <div class="w-100 set-fields">
            <b-form
              class="align-items-end flex-nowrap d-flex flex-row justify-content-between justify-content-sm-start justify-content-lg-between"
              style="max-width: 450px !important"
            >
              <b-form-group class="w-40 mr-1">
                <Multiselect
                  :class="{
                    error: $v.model.blogger_profile.main_audience.age_group.$anyError,
                  }"
                  :value="currentAudience"
                  @input="(val) => (model.blogger_profile.main_audience.age_group = val.value)"
                  placeholder="Возраст"
                  track-by="value"
                  label="display_name"
                  :show-labels="false"
                  :options="options.audiences.child.children.age_group.choices"
                />
              </b-form-group>
              <b-form-group class="w-20 mr-1 statistics__percent">
                <b-input
                  v-symbolic-filter="/[^0-9]/g"
                  :class="{
                    error: $v.model.blogger_profile.main_audience.age_group_percent.$anyError,
                  }"
                  v-model.number="model.blogger_profile.main_audience.age_group_percent"
                  type="text"
                  class="h-36 text-left"
                  name="stories"
                  id="stories7"
                />
              </b-form-group>
              <b-form-group class="mr-1">
                <SingleFileInput
                  @input="
                    (val) => {
                      uploadScreens({ audience: val });
                      audienceScreen = val;
                    }
                  "
                  :value="audienceScreen"
                  :selected="
                    model.blogger_profile.main_audience.screen.hasOwnProperty('full_size')
                  "
              /></b-form-group>
            </b-form>
            <div class="settings__info--text--down"></div>
          </div>

          <!-- <div class="settings__info--text--blue">Не продаю рекламу в сторис и не хочу заполнять охваты сторис</div> -->
        </div>
        <div class="settings__info--wrap statistics__info--wrap">
          <h4 class="settings__title--small">
            Преобладающая страна в <span style="font-weight: bold">процентах</span>
          </h4>
          <div class="statistics__title--desc">
            Заполните поля и загрузите подтверждающий скриншот со страной в процентах
          </div>
          <div class="w-100 set-fields">
            <b-form
              class="align-items-end flex-nowrap d-flex flex-row justify-content-between justify-content-sm-start justify-content-lg-between"
              style="max-width: 450px !important"
            >
              <b-form-group class="w-40 mr-1">
                <Multiselect
                  :class="{
                    error: $v.model.blogger_profile.main_country.country.$anyError,
                  }"
                  class="country__select"
                  v-model="model.blogger_profile.main_country.country"
                  placeholder="Выберите страну"
                  :show-labels="false"
                  :options="countries"
                  track-by="id"
                  label="name"
                />
              </b-form-group>
              <b-form-group class="w-20 mr-1 statistics__percent">
                <b-input
                  v-symbolic-filter="/[^0-9]/g"
                  :class="{
                    error: $v.model.blogger_profile.main_country.coverage.$anyError,
                  }"
                  v-model.number="model.blogger_profile.main_country.coverage"
                  type="text"
                  class="h-36 text-left"
                  name="stories"
                  id="stories6"
                />
              </b-form-group>
              <b-form-group class="mr-1">
                <SingleFileInput
                  @input="
                    (val) => {
                      uploadScreens({ country: val });
                      countryScreen = val;
                    }
                  "
                  :value="countryScreen"
                  :selected="model.blogger_profile.main_country.screen.hasOwnProperty('full_size')"
              /></b-form-group>
            </b-form>
            <div class="settings__info--text--down settings__info--text--down--error"></div>
          </div>
          <!-- <div class="settings__info--text--blue">Не продаю рекламу в сторис и не хочу заполнять охваты сторис</div> -->
        </div>
        <div class="settings__info--wrap statistics__info--wrap" style="padding-bottom: 0">
          <h4 class="settings__title--small">
            Преобладающий город в <span style="font-weight: bold">процентах</span>
          </h4>
          <div class="statistics__title--desc">
            Заполните поля и загрузите подтверждающий скриншот со страной в процентах
          </div>
          <div class="w-100 set-fields">
            <b-form
              class="align-items-end flex-nowrap d-flex flex-row justify-content-between justify-content-sm-start justify-content-lg-between"
              style="max-width: 450px !important"
            >
              <b-form-group class="w-40 mr-1">
                <CitySelect
                  v-model="model.blogger_profile.main_city.city"
                  deselectLabel=""
                  selectedLabel=""
                />
              </b-form-group>
              <b-form-group class="w-20 mr-1 statistics__percent">
                <b-input
                  v-symbolic-filter="/[^0-9]/g"
                  :class="{ error: $v.model.blogger_profile.main_city.coverage.$anyError }"
                  v-model.number="model.blogger_profile.main_city.coverage"
                  type="text"
                  class="h-36 text-left"
                  name="stories"
                  id="stories8"
                />
              </b-form-group>
              <b-form-group class="mr-1">
                <SingleFileInput
                  @input="
                    (val) => {
                      uploadScreens({ city: val });
                      cityScreen = val;
                    }
                  "
                  :value="cityScreen"
                  :selected="model.blogger_profile.main_city.screen.hasOwnProperty('full_size')"
              /></b-form-group>
            </b-form>

            <div class="settings__info--text--down"></div>
          </div>
          <!-- <div class="settings__info--text--blue">Не продаю рекламу в сторис и не хочу заполнять охваты сторис</div> -->
        </div>
      </div>
      <div class="statistics__col stata__how statistics__how--none statistics__col--padding">
        <div class="stata__ex">
          <h4 class="settings__title--small" style="margin-top: 0">
            Пример заполнения <span style="font-weight: bold"> охватов сторис</span>
          </h4>
          <img
            src="../../../assets/img/content/stataexample.png"
            alt="stataexample"
            class="statistics__example--pic"
          />
          <div class="statistics__example--text">
            Модератор не примет просмотры
            <span style="color: #ff0000">вместо </span>
            <span style="color: #ff0000; font-weight: 700">охватов.</span>
          </div>
          <div class="statistics__example--text">
            В статистике находите минимальное и максимальное число охватов сторис за последние 14
            дней. Заполняете в соответствующие поля и загружаете скриншот.
          </div>
          <div class="statistics__example--text">Пример скриншота с охватами сторис:</div>
          <img
            src="../../../assets/img/content/statastories.png"
            alt="stories"
            class="statistics__example--text"
          />
        </div>
        <div class="stata__ex">
          <h4 class="settings__title--small">
            Пример заполнения <span style="font-weight: bold"> охватов постов</span>
          </h4>
          <img
            src="../../../assets/img/content/exposts.png"
            alt="stataexample"
            class="statistics__example--pic"
          />
          <div class="statistics__example--text">
            Модератор не примет просмотры
            <span style="color: #ff0000">вместо </span>
            <span style="color: #ff0000; font-weight: 700">охватов.</span>
          </div>
          <div class="statistics__example--text">
            В статистике находите минимальное и максимальное число охватов постов за последние 30
            дней. Заполняете в соответствующие поля и загружаете скриншот.
          </div>
          <div class="statistics__example--text">Пример скриншота с охватами сторис:</div>
          <img
            src="../../../assets/img/content/screenposts.png"
            alt="stories"
            class="statistics__example--text"
          />
        </div>
        <div class="stata__ex">
          <h4 class="settings__title--small" style="margin-top: 0">
            Пример заполнения <span style="font-weight: bold"> процента женщин</span>
          </h4>
          <img
            src="../../../assets/img/content/exwoman.png"
            alt="stataexample"
            class="statistics__example--pic"
          />
          <div class="statistics__example--text">
            Модератор не примет просмотры
            <span style="color: #ff0000">вместо </span>
            <span style="color: #ff0000; font-weight: 700">охватов.</span>
          </div>
          <div class="statistics__example--text">
            В статистике вашего аккаунта находите процент женщин. Заполняете в соответствующее поле
            и загружаете скриншот.
          </div>
          <div class="statistics__example--text">Пример скриншота с охватами сторис:</div>
          <img
            src="../../../assets/img/content/exstata.png"
            alt="stories"
            class="statistics__example--text"
          />
        </div>
      </div>
    </div>
    <span v-if="error" class="err-txt_stat-form--mobile">{{ error }}</span>
    <div class="statistics__buttons">
      <b-button
        :disabled="loading"
        variant="outline-default"
        class="btn--stat__controls"
        @click="() => $emit('back')"
        >Назад</b-button
      >
      <span v-if="error" class="err-txt_stat-form">{{ error }}</span>
      <b-button variant="warning" class="btn--stat__controls" :disabled="loading" @click="submit">
        Далее
      </b-button>
    </div>
    <FillExample @close="showFillExample = false" :open="showFillExample" />
  </div>
</template>

<script>
import CitySelect from '@main/components/reusable/elements/selects/CitySelect.vue';
/* eslint-disable func-names, object-shorthand */

import FillExample from '@main/components/reusable/modals/FillExample.vue';
import Multiselect from 'vue-multiselect';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import StatisticFormValidationMixin from '@main/mixins/statistic/StatisticFormValidationMixin.vue';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import SingleFileInput from '../../forms/SingleFileInput.vue';
import bloggers from '../../../api/bloggers';
import auth from '../../../api/auth';

export default {
  components: {
    Multiselect,
    SingleFileInput,
    FillExample,
    CitySelect,
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        console.log(val);
      },
    },
  },
  model: {
    prop: 'model',
    event: 'input',
  },
  props: {
    model: Object,
  },
  mixins: [StatisticFormValidationMixin],
  data: () => ({
    error: '',
    loading: false,
    coverageScreen: null,
    countryScreen: null,
    audienceScreen: null,
    postCoverageScreen: null,
    cityScreen: null,
    womenScreen: null,
    fileLoading: false,
    ageGroup: 0,
    showFillExample: false,
    noStories: false,
    screenError: false,
  }),
  computed: {
    ...mapState(['currentUser', 'bloggerOptions', 'tags', 'countries', 'cities']),
    ...mapGetters(['getUserModel']),
    options() {
      return this.bloggerOptions.actions.POST;
    },
    currentAudience() {
      return this.options.audiences.child.children.age_group.choices.filter(
        (val) => val.value === this.model.blogger_profile.main_audience.age_group
      )[0];
    },
    coverageScreenFilled() {
      if (!this.noStories) {
        return (
          !this.lodash.isEmpty(this.currentUser.blogger_profile.coverage_screen) ||
          (this.coverageScreen && this.coverageScreen.size > 0)
        );
      }
      return true;
    },
    postCoverageScreenFilled() {
      if (
        this.noStories ||
        this.model.blogger_profile.coverage_posts_min ||
        this.model.blogger_profile.coverage_posts_max
      ) {
        return (
          !this.lodash.isEmpty(this.currentUser.blogger_profile.posts_coverage_screen) ||
          (this.postCoverageScreen && this.postCoverageScreen.size > 0)
        );
      }
      return true;
    },
    countryScreenFilled() {
      return (
        !this.lodash.isEmpty(this.currentUser.blogger_profile.main_country.screen) ||
        (this.countryScreen && this.countryScreen.size > 0)
      );
    },
    audienceScreenFilled() {
      return (
        !this.lodash.isEmpty(this.currentUser.blogger_profile.main_audience.screen) ||
        (this.audienceScreen && this.audienceScreen.size > 0)
      );
    },
    cityScreenFilled() {
      return (
        !this.lodash.isEmpty(this.currentUser.blogger_profile.main_city.screen) ||
        (this.cityScreen && this.cityScreen.size > 0)
      );
    },
    womenScreenFilled() {
      return (
        !this.lodash.isEmpty(this.currentUser.blogger_profile.women_percent_screen) ||
        (this.womenScreen && this.womenScreen.size > 0)
      );
    },
  },
  methods: {
    ...mapActions(['loadUser']),
    ...mapMutations('notifications', ['setMessage']),
    async submit() {
      this.loading = true;
      this.$v.model.$touch();
      this.screenError =
        !this.womenScreenFilled ||
        !this.countryScreenFilled ||
        !this.cityScreenFilled ||
        !this.audienceScreenFilled ||
        !this.postCoverageScreenFilled ||
        !this.coverageScreenFilled;

      if (this.$v.model.$anyError || this.screenError) {
        this.error = 'Заполните поля отмеченные красным и загрузите скриншоты';
        this.loading = false;
        return;
      }
      this.$parent.error = '';
      try {
        await auth.updateMe(this.model);
        this.$emit('next');
      } catch (e) {
        this.setMessage({
          text:
            'Произошла ошибка при сохранении профиля. Попробуйте позднее или свяжитесь с администрацией!',
          iconPath: alertGreyIcon,
        });
      }
      this.loading = false;
    },
    async uploadScreens(data) {
      await bloggers.uploadScreens(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.country__select {
  width: 155px;
}
@media (max-width: 325px) {
  .statistics__percent {
    max-width: 80px !important;
    width: none !important;
  }
  ::v-deep .multiselect__single {
    max-width: 50px !important;
  }
}
.settings__input {
  padding-right: 16px !important;
}
.statistics__percent {
  max-width: 70px !important;
  text-align: left;
  min-width: 55px !important;
}
.input--wd {
  text-align: left;
}
.mobile-screens_btn {
  margin-top: 15px;
}
.coverage-blocks {
  flex-wrap: inherit !important;
}
::v-deep .form-group {
  margin-bottom: 0px !important;
}
</style>
