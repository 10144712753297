<template>
  <div v-if="model && me">
    <h3 class="statistics__format--title">Заполните форматы рекламы</h3>
    <template v-if="this.prTypes">
      <PrTypeForm
        @delete="decompose(type.uid)"
        v-model="me.blogger_profile.pr_types[getIdxById(type.uid)]"
        :ref="idx"
        :options="options"
        v-for="(type, idx) in me.blogger_profile.pr_types"
        :key="`${type.uid}-${idx}-prtpFrmMn`"
      />
    </template>
    <b-button @click="increment" variant="outline-default mt-3 h-36">
      Добавить новый формат рекламы
    </b-button>
    <p v-if="error" style="text-align: left" class="err-txt_stat-form--mobile">
      {{ error }}
    </p>
    <div class="statistics__buttons">
      <b-button
        style="min-height: 36px !important"
        class="btn--stat__controls"
        variant="outline-default"
        @click="() => $emit('back')"
        >Назад</b-button
      >
      <span v-if="error" class="err-txt_stat-form">{{ error }}</span>
      <b-button
        style="min-height: 36px !important"
        :disabled="loading"
        variant="warning"
        class="btn--stat__controls"
        @click="submit"
      >
        Далее
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import auth from '@main/api/auth';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import PrTypeForm from '../../reusable/statistics/PrTypeForm.vue';

export default {
  components: {
    PrTypeForm,
  },
  model: {
    prop: 'me',
    event: 'input',
  },
  props: {
    me: Object,
  },
  data: () => ({
    error: '',
    model: null,
    prTypes: [],
    loading: false,
  }),
  computed: {
    ...mapState(['bloggerOptions', 'currentUser']),
    options() {
      return this.bloggerOptions.actions.POST;
    },
  },
  methods: {
    ...mapMutations('notifications', ['showMessage']),
    async init() {
      this.$set(
        this,
        'model',
        this.lodash.cloneDeep(this.bloggerOptions.actions.POST.pr_types.child.children)
      );
      const keys = Object.keys(this.model);
      for (let i = 0; i < keys.length; i += 1) {
        this.model[keys[i]] = null;
      }
      this.model.uid = this.getUid();
      this.me.blogger_profile.pr_types = this.me.blogger_profile.pr_types.map((val) => {
        return { ...val, ...{ uid: this.getUid() } };
      });
      if (!this.me.blogger_profile.pr_types.length) {
        this.me.blogger_profile.pr_types = [this.model];
      }
    },
    getUid() {
      return this.me.blogger_profile.pr_types.length + Math.floor(Math.random() * 100000);
    },
    submit() {
      this.$parent.error = '';
      if (!this.me.blogger_profile.pr_types.length) {
        this.$parent.error = 'Заполните хотя-бы один формат!';
        return;
      }
      const refArr = Object.entries(this.$refs);
      refArr.forEach((item) => {
        const ref = item[1][0];
        if (ref) {
          ref.$v.$touch();
        }
      });
      const valid = refArr.every((item) => {
        const ref = item[1][0];
        if (!ref) {
          return true;
        }
        return !ref.$v.$anyError;
      });
      if (valid) {
        this.loading = true;
        auth
          .updateMe(this.me)
          .then((res) => {
            this.prTypes = res.data.blogger_profile.pr_types;
            this.$emit('next');
          })
          .catch((e) => {
            console.log(e);
            if (e.response.data.blogger_profile.pr_types) {
              this.showMessage({
                title:
                  "Заполните поля, отмеченные красным и нажмите 'Далее', либо удалите незаполненный формат!",
              });
              return;
            }
            this.setWindow({
              text:
                'Произошла ошибка при сохранении форматов рекламы, попробуйте позже или обратитесь в техническую поддержку - help@easyprbot.com',
              iconPath: alertGreyIcon,
            });
          });
        this.loading = false;
        return;
      }
      this.error = 'Заполните поля отмеченные красным и нажмите далее';
    },
    increment() {
      const mod = this.lodash.cloneDeep(this.model);
      mod.uid = this.getUid();
      this.me.blogger_profile.pr_types.push(mod);
    },
    decompose(uid) {
      this.me.blogger_profile.pr_types.splice(this.getIdxById(uid), 1);
    },
    getIdxById(uid) {
      return this.me.blogger_profile.pr_types.indexOf(
        this.me.blogger_profile.pr_types.filter((val) => val.uid === uid)[0]
      );
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style scoped>
.format__add {
  margin-top: 15px;
}
@media (max-width: 366px) {
  .format__add {
    width: 100% !important;
  }
}
@media (max-width: 361px) {
  .btn-back--reviews {
    width: 60% !important;
  }
}
</style>
