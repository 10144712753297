<template>
  <div class="container__stata">
    <section class="section__stat-form statistics__format">
      <div class="statistics__format--header">
        <ul class="statistics__format--list">
          <li
            v-for="step in stepObject"
            :key="`${step.number}-stobjhfRd`"
            :class="[
              'statistics__format--item',
              step.number === currentStep ? 'statistics__format--item-active' : '',
            ]"
          >
            Шаг {{ step.number }}. {{ step.name }}
          </li>
        </ul>
        <div :class="['statistics__line', `statistics__line--${currentStep}`]"></div>
      </div>
      <div class="statistics__format--header--mobile">
        <div class="mobile__text">
          Шаг {{ currentStep }} из {{ steps }}.
          {{ stepObject.filter((val) => val.number === currentStep)[0].name }}
        </div>
      </div>
      <ul class="format__list">
        <li v-if="model && currentUser && bloggerOptions" class="format__item format__item-active">
          <keep-alive>
            <component
              @next="
                () => {
                  currentStep < steps ? (currentStep += 1) : null;
                }
              "
              @back="
                () => {
                  currentStep > 1 ? (currentStep -= 1) : null;
                }
              "
              :is="
                stepObject.filter((val) => {
                  return val.number === currentStep;
                })[0].component
              "
              v-model="model"
              :ref="currentStep"
            ></component>
          </keep-alive>
        </li>
        <Loader v-else />
      </ul>
      <div
        class="mt-5 pb-3 px-3 d-flex align-items-center flex-wrap"
        style="background-color: #f8f8f8; border-radius: 10px"
      >
        <p style="color: #6a7b83" class="mr-mlg-4 mt-3">
          Нашли ошибку или регистрация карточки не переходит на следующий шаг? Заполните эту форму
        </p>
        <b-button
          href="https://tracker.easyprbot.com/bug-form"
          target="_blank"
          class="h-36 mt-3"
          variant="outline-default"
          >Заполните эту форму</b-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Loader from '@main/components/Loader.vue';
import First from './steps/First.vue';
import Second from './steps/Second.vue';
import Third from './steps/Third.vue';
import Fourth from './steps/Fourth.vue';

export default {
  components: {
    First,
    Second,
    Third,
    Fourth,
    Loader,
  },
  methods: {
    ...mapActions(['loadUser']),
    loadModel() {
      this.$set(this, 'model', this.lodash.cloneDeep(this.currentUser));
      if (!this.model.blogger_profile) {
        this.model.blogger_profile = this.getUserModel;
      }
    },
    initCurrentStep() {
      const qstep = this.$route.query.step;
      if (qstep) {
        this.currentStep = Number(qstep);
        return;
      }
      const lstep = Number(localStorage.getItem('lastFillstatStep'));
      if (lstep) this.currentStep = lstep;
    },
  },
  computed: {
    ...mapGetters(['getUserModel']),
    ...mapState(['currentUser', 'bloggerOptions']),
  },
  data: () => ({
    model: null,
    error: '',
    steps: 4,
    currentStep: 1,
    stepObject: [
      { number: 1, component: 'First', name: 'Верификация аккаунта' },
      { number: 2, component: 'Second', name: 'Форматы рекламы' },
      { number: 3, component: 'Third', name: 'Статистика аккаунта' },
      { number: 4, component: 'Fourth', name: 'Темы аккаунта' },
    ],
  }),
  async mounted() {
    await this.loadUser();
    this.loadModel();
    this.initCurrentStep();
  },
  watch: {
    currentStep(val) {
      window.scrollTo(0, 0);
      localStorage.setItem('lastFillstatStep', String(val));
    },
  },
};
</script>

<style scoped lang="scss">
.container__stata {
  font-family: Rubik !important;
}

::v-deep .multiselect__select {
  height: 33px !important;
}

::v-deep .multiselect__tag {
  margin-bottom: 0px !important;
}

::v-deep .multiselect__placeholder {
  margin-bottom: 0px !important;
}
::v-deep .multiselect__single {
  display: block ruby;
  margin: auto auto !important;
  line-height: 22px;
  white-space: nowrap !important;
  max-width: 150px;
  overflow-x: hidden !important;
  text-overflow: ellipsis !important;
}
::v-deep .multiselect__placeholder {
  display: block ruby !important;
  margin: auto auto !important;
  line-height: 22px !important;
  max-height: 36px !important;
  white-space: nowrap !important;
  max-width: 100px !important;
  overflow-x: hidden !important;
  text-overflow: ellipsis !important;
}
::v-deep .multiselect__tags {
  padding: 5px 40px 1px 8px !important;
  border: 1px solid #d5dfe4 !important;
  min-height: 36px !important;
  max-width: 100% !important;
  width: 100% !important;
  min-width: 90px !important;
}
::v-deep .multiselect {
  border-radius: 5px !important;
  width: 100% !important;
  min-height: 36px !important;
  min-width: 90px !important;
}
::v-deep .main {
  margin-top: 75px !important;
}
.input--wd {
  min-width: 80px !important;
}
</style>
