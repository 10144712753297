<template>
  <div class="">
    <InstagramVerificationWindow class="blck" />
    <InstagramVerificationWindow
      class="blck"
      v-if="currentUser.blogger_profile && currentUser.blogger_profile.instagram_verified"
      :prAccount="true"
    />
    <b-button
      @click="setVideoWindow"
      variant="outline-default d-block mx-auto py-2 fs-16 justify-content-center"
      class="blck d-flex align-items-center"
    >
      <img
        class="mr-3"
        style="width: 30px; height: 36px"
        src="@main/assets/img/svg/yt_icon.svg"
        alt=""
      />
      Как заполнять карточку блогера</b-button
    >
    <span v-if="error" class="err-txt_stat-form--mobile">{{ error }}</span>
    <div class="statistics__buttons">
      <b-button
        class="btn--stat__controls"
        variant="outline-default"
        @click="() => $router.push('/reviews')"
        >Назад к отзывам</b-button
      >
      <span v-if="error" class="err-txt_stat-form">{{ error }}</span>
      <b-button
        :disabled="!currentUser.blogger_profile || !currentUser.blogger_profile.instagram_verified"
        class="btn--stat__controls"
        variant="warning"
        @click="submit"
      >
        Далее
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import Modal from '@main/components/reusable/modals/Modal.vue';
import bloggers from '@main/api/bloggers';
import InstagramVerificationWindow from '@main/components/reusable/windows/InstagramVerificationWindow.vue';

export default {
  components: {
    InstagramVerificationWindow,
  },
  model: {
    prop: 'model',
    event: 'input',
  },
  computed: {
    ...mapState(['currentUser']),
  },
  props: {
    model: Object,
  },
  data: () => ({
    error: '',
  }),
  methods: {
    ...mapMutations('notifications', ['setWindow']),
    ...mapActions(['loadUser']),
    async submit() {
      await this.loadUser();
      this.$parent.loadModel();
      this.$emit('next');
    },
    setVideoWindow() {
      this.setWindow({
        video: {
          attrs: {
            src: 'https://www.youtube.com/embed/UPc_9msJtMQ',
            type: 'iframe',
            aspect: '1by1',
            allowfullscreen: true,
          },
        },
        attrs: { wrapClass: 'modal-md first-step-video-modal' },
      });
    },
  },
};
</script>

<style>
.first-step-video-modal {
  width: 600px !important;
}
</style>

<style lang="scss" scoped>
.h-36 {
  height: 36px;
}
.img-acc-exmpl {
  max-width: 350px !important;
  width: 100% !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.static-inp {
  transition: 0.3s ease-in-out;
}
.err-txt {
  margin-bottom: 15px;
  color: red;
  font-size: 0.9rem;
}
.blck {
  width: 45%;
  margin: 3rem auto;
}
@media (max-width: 768px) {
  .blck {
    width: 100%;
    margin: 3rem auto;
  }
}
</style>
