var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container__stata"},[_c('section',{staticClass:"section__stat-form statistics__format"},[_c('div',{staticClass:"statistics__format--header"},[_c('ul',{staticClass:"statistics__format--list"},_vm._l((_vm.stepObject),function(step){return _c('li',{key:((step.number) + "-stobjhfRd"),class:[
            'statistics__format--item',
            step.number === _vm.currentStep ? 'statistics__format--item-active' : '' ]},[_vm._v(" Шаг "+_vm._s(step.number)+". "+_vm._s(step.name)+" ")])}),0),_c('div',{class:['statistics__line', ("statistics__line--" + _vm.currentStep)]})]),_c('div',{staticClass:"statistics__format--header--mobile"},[_c('div',{staticClass:"mobile__text"},[_vm._v(" Шаг "+_vm._s(_vm.currentStep)+" из "+_vm._s(_vm.steps)+". "+_vm._s(_vm.stepObject.filter(function (val) { return val.number === _vm.currentStep; })[0].name)+" ")])]),_c('ul',{staticClass:"format__list"},[(_vm.model && _vm.currentUser && _vm.bloggerOptions)?_c('li',{staticClass:"format__item format__item-active"},[_c('keep-alive',[_c(_vm.stepObject.filter(function (val) {
                return val.number === _vm.currentStep;
              })[0].component,{ref:_vm.currentStep,tag:"component",on:{"next":function () {
                _vm.currentStep < _vm.steps ? (_vm.currentStep += 1) : null;
              },"back":function () {
                _vm.currentStep > 1 ? (_vm.currentStep -= 1) : null;
              }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1):_c('Loader')],1),_c('div',{staticClass:"mt-5 pb-3 px-3 d-flex align-items-center flex-wrap",staticStyle:{"background-color":"#f8f8f8","border-radius":"10px"}},[_c('p',{staticClass:"mr-mlg-4 mt-3",staticStyle:{"color":"#6a7b83"}},[_vm._v(" Нашли ошибку или регистрация карточки не переходит на следующий шаг? Заполните эту форму ")]),_c('b-button',{staticClass:"h-36 mt-3",attrs:{"href":"https://tracker.easyprbot.com/bug-form","target":"_blank","variant":"outline-default"}},[_vm._v("Заполните эту форму")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }